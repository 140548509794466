@import '../settings';

.wrapForm {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 70px 10px 10px;
    border-top: 1px solid $border-default;
    background-color: $bg-light;
}

.wrapText {
    width: 100%;
}

.button {
    margin-left: 20px !important;
}

.buttonText {
    padding-left: 5px;
}
