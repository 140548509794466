@import '../settings';

@import '../Layout/mixin/flex_box';
@import '../Layout/mixin/breakpoints';

.nav {
    margin: 0 auto;
    color: white;

    @include horizontal();
    overflow: hidden;
    align-items: center;

    > div {
        &:first-child {
            display: flex;
            align-items: center;

            h1 {
                font-weight: normal;
                color: white;
                font-size: $font-size-default;
                padding: $padding-micro $padding-small;
                min-width: 300px;
                display: inline-block;
            }
        }

        &:last-child {
            text-align: right;
        }
    }

    .menu {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        gap: $padding-small;
    }

    a {
        display: inline-block;
        color: $font-color-light;
        padding: 2px;
    }

    .userIcon {
        border: 1px solid $font-color-light;
        border-radius: 50%;
        margin-left: $padding-full;
    }

    .timer {
        padding: $padding-micro;
        text-align: center;
    }
}
