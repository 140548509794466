@import '../_settings.scss';
@import '../Layout/mixin/flex_box.scss';

.container {
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.6);
    font-size: $font-size-default;
    border-bottom: 1px solid $border-default;
    overflow: hidden;
}

.headline {
    font-size: $font-size-small;
    padding: 3px;
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.05);

    @include horizontal() {
        flex-grow: 1;
        flex-shrink: 1;
    }

    align-items: center;

    div:last-child {
        text-align: right;
    }
}

.actions {
    text-align: right;
    float: right;
    margin-top: $padding-micro * -1;
    margin-right: $padding-micro * -1;
}

.body {
    padding: $padding-micro;
}
