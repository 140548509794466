@import '../settings';
@import '../Layout/mixin/breakpoints';
@import '../Layout/mixin/flex_box';

.rowTitle {
    padding: $padding-micro;
    background-color: $bg-light;
    border-bottom: 1px solid $border-default;
    color: $font-color-medium;
    font-family: Ubuntu, serif;
    font-size: $font-size-small;
    justify-content: flex-start;
    width: 100%;

    h3 {
        display: inline-block;
    }
}
