$marginPage: 1rem;
$line-height: 1.5em;

/** fonts **/
$font-family-default: 'Montserrat', 'Open Sans', 'Helvetica Neue', 'Helvetica',
    Arial, sans-serif;
$font-family-accent: 'Roboto', 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
$font-family-mono: 'SpaceMono';

/**   colors   **/
$color-grey: #333;
$color-grey-dark: darken($color-grey, 7%);
$color-grey-light: lighten($color-grey, 20%);
$color-blue: #0593e7;
$color-orange: #ffa800;
$color-gray: $color-grey;
$color-black: darken($color-grey-dark, 8%);
$color-grey-2: #ddd;
$color-grey-3: #999;
$color-white: #fff;

/**   backgrounds   **/
$bg-default: $color-white;
$bg-accent: $color-blue;
$bg-accent-dark: darken($bg-accent, 20%);
$bg-darken: darken($bg-default, 20%);
$bg-light: darken($bg-default, 5%);
$bg-paper: darken($bg-default, 2%);

/**   borders   **/
$border-accent: $color-blue;
$border-accent-dark: darken($border-accent, 20%);
$border-darken: $color-grey-3;
$border-default: $color-grey-2;
$border-light: $color-grey-3;

/** font-size **/
$font-size-default: 16px;
$font-size-huge: 34px;
$font-size-large: 18px;
$font-size-small: 14px;
$font-size-micro: 10px;

/** font-color **/
$font-color-default: $color-grey;

/** padding **/
$padding: $font-size-default;
$padding-full: 80px;
$padding-half: 40px;
$padding-small: 20px;
$padding-micro: 10px;

/**   font-color   **/
$font-color-default: $color-grey;
$font-color-light: $color-grey-3;
$font-color-medium: #777;
$font-color-darken: $color-grey-dark;
$font-color-accent: $color-blue;

/** grid **/
$grid-gap-micro: $padding-micro;
$grid-gap-small: $padding-small;
$grid-gap-half: $padding-half;
$grid-gap-full: $padding-full;

$max-width: 1400px;

/** shadows **/
$shadow-default: 0 0 8px rgba(0, 0, 0, 0.3);
$shadow-small: 0 0 5px rgba(0, 0, 0, 0.2);

/** border-radius **/
$border-radius-default: 4px;
