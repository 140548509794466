$grid-columns: 12 !default;
$padding: 20px !default;

@mixin col($width: 0, $grow: 0, $shrink: 1) {
    flex-grow: $grow;
    flex-shrink: $shrink;

    @if ($width > 0) {
        flex-basis: percentage(calc($width / 12));
    }

    @content;
}

@mixin stretch() {
    align-items: center;
    justify-content: space-between;
    align-content: stretch;
}

@mixin horizontal(
    $rows: 0,
    $selector: 'div',
    $justify-content: center,
    $gap: 0,
    $overflow: auto
) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: $justify-content;
    gap: $gap;
    overflow: $overflow;

    > #{$selector} {
        @content;

        @if ($rows > 0) {
            @include col(calc(12 / $rows));
        }
    }
}

@mixin vertical-padding($nth: 1) {
    > div {
        padding-bottom: calc($padding / 4);
        padding-right: 0;
        padding-left: 0;

        @include laptop() {
            padding-bottom: calc($padding / 2);
        }

        @include wide() {
            padding-bottom: $padding;
        }

        @if ($nth > 1) {
            &:nth-child(#{$nth}n + 0) {
                padding-left: calc($padding / 4);

                @include laptop() {
                    padding-bottom: calc($padding / 2);
                }

                @include wide() {
                    padding-bottom: $padding;
                }
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

@mixin horizontal-padding($nth: 1) {
    > div {
        padding-right: calc($padding / 4);
        padding-bottom: 0;

        @include laptop() {
            padding-right: calc($padding / 2);
        }

        @if ($nth > 1) {
            padding-bottom: calc($padding / 4);
            @include laptop() {
                padding-bottom: calc($padding / 2);
            }

            @include wide() {
                padding-bottom: $padding;
            }

            &:nth-child(#{$nth}n + 0) {
                padding-left: calc($padding / 4);

                @include laptop() {
                    padding-left: calc($padding / 2);
                }
            }
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

@mixin vertical(
    $cols: 0,
    $selector: 'div',
    $gap: 0,
    $justify: center,
    $align-items: stretch
) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: $align-items;
    flex-wrap: wrap;
    justify-content: $justify;
    align-content: center;
    gap: $gap;

    > #{$selector} {
        flex: 1 1 0;

        @if ($cols > 0) {
            @include col(calc(12 / $cols)) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @content;
    }
}
