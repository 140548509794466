@import '../settings';

@import '../Layout/mixin/flex_box';
@import '../Layout/mixin/breakpoints';

.question {
    padding: $padding-small;
    margin-bottom: 5px;
    border: 1px solid $border-default;
    background-color: $bg-default;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.actions {
    float: right;
    text-align: right;
    margin-right: $padding-micro * -1;
    margin-top: $padding-micro * -1;
}

.title {
    padding-left: 30px;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
    padding-right: $padding-micro;
    padding-bottom: $padding-micro;
}

.state_row {
    text-align: right;
    margin-bottom: $padding-small * -1;
    margin-right: $padding-small * -1;
}
