@import '../_settings.scss';
@import '../Layout/mixin/flex_box.scss';

.wrapper {
    margin: 20px ($padding-small * -1) 0 ($padding-small * -1);
    background-color: $bg-light;
}

.headline {
    font-size: $font-size-small;
    color: $font-color-light;
    padding: 0;
    text-align: right;

    @include horizontal();

    overflow: visible;
    justify-content: space-between;
    align-items: center;
}
