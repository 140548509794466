@import '../settings';

.scrollable {
    position: relative;
    flex-grow: 1 !important;
    overflow-y: scroll;
    box-shadow:
        inset -5px 3px 5px 0 rgba(0, 0, 0, 0.2),
        inset -5px -3px 5px 0 rgba(0, 0, 0, 0.2);
    padding: $padding-small 70px $padding-small $padding-small;
    background-color: $bg-light;
}
