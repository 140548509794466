@import '../settings';

@import '../Layout/mixin/flex_box';
@import '../Layout/mixin/breakpoints';

.wrapper,
.actions {
    @include horizontal();
    padding: $padding-small;
}

.wrapper {
    justify-content: space-between;
}

.actions {
    justify-content: flex-end;

    button {
        margin-right: $padding-small;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.input {
    justify-content: center;
    border-bottom: 1px solid $border-default;
    padding-top: $padding-micro;
}

.text {
    width: 100%;
    text-align: center;
    padding-bottom: 0;
}

.info {
    width: 100%;
}
