@import '../settings';
@import '../Layout/mixin/flex_box';

.chat {
    @include vertical() {
        width: 100%;
        flex-grow: 0;
    }

    scrollbar-color: $border-accent #eee;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    box-shadow: $shadow-default;
    border: 1px solid $border-light;
    z-index: 200;
    background-color: $bg-default;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100dvh;

    > div:first-of-type {
        padding: $padding-micro;
        background-color: $bg-light;
        border-bottom: 1px solid $border-default;
        color: $font-color-medium;
        font-family: Ubuntu, serif;
        font-size: $font-size-small;
        justify-content: flex-start;
        width: 100%;

        h3 {
        }
    }
}

.headline {
    @include horizontal();

    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 10px;
    height: 5dvh;

    overflow: visible;
}
