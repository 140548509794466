@import '../../_settings.scss';
@import '../../Layout/mixin/flex_box';

body {
    min-height: 100%;
    width: 100%;

    #root {
        border: 1px solid black;
    }
}

.wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    > div {
        width: 80%;
    }
    h1 {
        text-align: center;
    }
}

.actions {
    padding-top: $padding-small;
    text-align: right;

    button {
        margin-right: $padding-small;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
