@import '../settings';
@import '../Layout/mixin/flex_box';

.container {
    padding: $padding-half;
    @include vertical();
    gap: $padding-half;

    * {
    }

    h4 {
        margin-bottom: $padding-micro;
    }

    hr {
        color: $border-light;
        border-top: 1px solid $border-default;
    }
}
