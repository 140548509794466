@import '../../settings';
@import '../../Layout/mixin/flex_box';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import '@fontsource/ubuntu/400.css';
@import '@fontsource/ubuntu/700.css';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.container {
    @include vertical();
    height: auto;
    width: 50%;

    textarea {
        width: 100%;
        border: 1px solid $border-accent;
        padding: $padding-micro;
        border-radius: $border-radius-default;
    }
}

.title {
    padding: $padding-small 0;
}

.actions {
    padding-top: $padding-small;
    text-align: right;

    button {
        margin-right: $padding-micro;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
