@import '../settings';
@import '../Layout/mixin/flex_box';

.question_row {
    overflow: scroll;
    overflow-x: visible;
    border-right: 1px solid $border-default;
    padding: 5px 5px $padding-full;

    scrollbar-color: $border-accent #eee;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

.container_hover {
    background-color: rgba(5, 147, 234, 0.3);
}

.container,
.container_hover {
    @include vertical() {
        flex-grow: 1;
    }

    align-items: stretch;
    flex-wrap: nowrap;
    background-color: #fafafa;
}

.rowTitle {
    z-index: 200;
    flex-grow: 0 !important;
}
