@import '../settings';

@import '../Layout/mixin/flex_box';
@import '../Layout/mixin/breakpoints';

.timer {
    display: flex;
    align-items: center;
    padding: $padding-small;

    h4 {
        display: inline-block;
        font-size: $font-size-large;
        height: 100%;
    }

    button {
        &:nth-of-type(2) {
            margin-right: $padding-small;
        }
    }
}

.active {
}

.paused {
    background-color: $color-orange;
    border-radius: 5px;
    h4 {
        padding-right: $padding-small;
    }
}

.create {
    display: none;
}
