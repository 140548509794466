@import '../settings';
@import '../Layout/mixin/flex_box';

.message {
    padding: $padding-micro;
    text-align: left;
    margin-bottom: 8px;
}

.message_own {
    position: relative;
    padding-left: 20px;
}

.wrapper {
    position: relative;
    padding-right: 20px;
}

.marker {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 201;
    border: 1px solid $border-default;
    background-color: $bg-default;

    transform: translate(-45%, 100%) rotate(45deg);
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);

    .message_own & {
        left: auto;
        right: 0;
        transform: translate(45%, 50%) rotate(45deg);
        clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
    }
}

.author {
    font-size: $font-size-small;
    color: $font-color-medium;
    padding-right: $padding-small;
    padding-bottom: 4px;
}

.time {
    padding-top: 10px;
    float: right;
    font-size: $font-size-small;
    color: $font-color-light;
    .message_own & {
        padding-top: 3px;
    }
}
