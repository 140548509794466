@import '../_settings.scss';
@import '../Layout/mixin/flex_box.scss';

.container {
    @include horizontal(1) {
        flex-grow: 1;
    }

    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: 100dvh;

    > div {
        text-align: center;
        padding: $padding-half;
        background-color: $bg-light;
        color: $font-color-medium;
    }

    a {
        color: $font-color-accent;
    }
}
