@import '../../settings';
@import '../../Layout/mixin/flex_box';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import '@fontsource/ubuntu/400.css';
@import '@fontsource/ubuntu/700.css';

$navbarHeight: 6dvh;

html {
    font-family: 'Roboto', sans-serif;
}

.header {
    border-bottom: 1px solid $border-default;
    height: $navbarHeight;
    background-color: $bg-accent;
}

.unpublished {
    background-color: $color-orange;
}

.main {
    height: 92dvh;
    overflow: hidden;
}

.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;

    .MuiPaper-root {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
}

$drawer-width: 60px;

.drawer {
    border-left: 1px solid $border-default;
    border-bottom: 1px solid $border-default;
    flex-grow: 0 !important;
    width: $drawer-width;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bg-default;
    z-index: 700;
    height: 100dvh;
    transition: width 0.3s ease-in-out;
}

.drawer_header {
    height: $navbarHeight;
}

.container {
    overflow: hidden;
    padding-right: 60px;
    position: relative;

    height: 100dvh;
    width: 100dvw;
}
