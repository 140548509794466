@import '../_settings.scss';
@import '../Layout/mixin/breakpoints';

html,
body {
    height: 100%;
    width: 100%;
}

.wrapper {
    background-color: $bg-paper;
    width: 100%;
    padding: $padding-half;
    box-shadow: $shadow-small;
    border-radius: 8px;
}

.login {
    color: $font-color-medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;

    @include tablet() {
        width: 50%;
    }
    min-height: 100dvh;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    form {
        width: 100%;
    }

    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}
