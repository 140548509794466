@import '../settings';
@import '../Layout/mixin/flex_box';

.wrapper {
    padding: $padding-micro;
    color: $font-color-medium;
    font-size: $font-size-small;
    margin-top: $padding-micro;
}

.icon {
    padding-right: $padding-micro;
    display: inline-block;
    color: $font-color-accent;
}

.infoText {
    color: $font-color-medium;
    display: inline-block;
    font-size: $font-size-small;
}

.box {
    background-color: $bg-light;
    border: 1px solid $border-default;
}

.plain {
}
